import React, { useState, useEffect } from 'react';
import { Mail, Package2, Plus } from 'lucide-react';

const Schwundliste = () => {

  const [produkte, setProdukte] = useState([
    { id: 1, name: 'Bockwurst', anzahl: 0, kategorie: 'Fleisch-Produkte' },
    { id: 2, name: 'Frikadellen', anzahl: 0, kategorie: 'Fleisch-Produkte' },
    { id: 3, name: 'Leberkäse', anzahl: 0, kategorie: 'Fleisch-Produkte' },
    { id: 4, name: 'Leberkäse Pizza', anzahl: 0, kategorie: 'Fleisch-Produkte' },
    { id: 5, name: 'Wiener Crossant', anzahl: 0, kategorie: 'Fleisch-Produkte' },
    { id: 6, name: 'Schokobrötchen', anzahl: 0, kategorie: 'Backwaren' },
    { id: 7, name: 'Laugenbrezel', anzahl: 0, kategorie: 'Backwaren' },
    { id: 8, name: 'Schinken-Käse Crossant', anzahl: 0, kategorie: 'Backwaren' },
    { id: 9, name: 'Nougat Crossant', anzahl: 0, kategorie: 'Backwaren' },
    { id: 10, name: 'Marzipan Crossant', anzahl: 0, kategorie: 'Backwaren' },
    { id: 11, name: 'Butter-Crossant', anzahl: 0, kategorie: 'Backwaren' },
    { id: 12, name: 'Brötchen', anzahl: 0, kategorie: 'Brötchen' },
    { id: 13, name: 'Bröchten (Schinken)', anzahl: 0, kategorie: 'Brötchen' },
    { id: 13, name: 'Bröchten (Käse)', anzahl: 0, kategorie: 'Brötchen' },
  ]);

  useEffect(() => {
    document.title = "Schwundliste";
  }, []);

  const erhoeheAnzahl = (id) => {
    setProdukte(produkte.map(produkt => 
      produkt.id === id 
        ? { ...produkt, anzahl: produkt.anzahl + 1 } 
        : produkt
    ));
  };

  const resetAnzahl = (id) => {
    setProdukte(produkte.map(produkt => 
      produkt.id === id 
        ? { ...produkt, anzahl: 0 } 
        : produkt
    ));
  };

  const erstelleEmailInhalt = () => {
    const datum = new Date().toLocaleDateString();
    let emailText = `Schwundliste vom ${datum}\n\n`;
    
    const nachKategorie = produkte.reduce((acc, produkt) => {
      if (produkt.anzahl > 0) {
        if (!acc[produkt.kategorie]) {
          acc[produkt.kategorie] = [];
        }
        acc[produkt.kategorie].push(produkt);
      }
      return acc;
    }, {});

    Object.entries(nachKategorie).forEach(([kategorie, items]) => {
      emailText += `${kategorie}:\n`;
      items.forEach(produkt => {
        emailText += `- ${produkt.name}: ${produkt.anzahl} Stück\n`;
      });
      emailText += '\n';
    });
    
    const gesamtAnzahl = produkte.reduce((sum, p) => sum + p.anzahl, 0);
    emailText += `\nGesamtanzahl Produkte: ${gesamtAnzahl}`;
    
    return emailText;
  };

  const sendePerEmail = () => {
    const emailInhalt = erstelleEmailInhalt();
    const subject = `Schwundliste vom ${new Date().toLocaleDateString()}`;
  
    fetch('http://localhost:3001/send-email', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ subject, body: emailInhalt }),
    })
      .then(response => {
        if (response.ok) {
          alert('E-Mail wurde erfolgreich gesendet.');
        } else {
          alert('Fehler beim Senden der Email im ersten');
        }
      })
      .catch(error => console.error('Fehler:', error));
  };
  

  const produkteNachKategorie = produkte.reduce((acc, produkt) => {
    if (!acc[produkt.kategorie]) {
      acc[produkt.kategorie] = [];
    }
    acc[produkt.kategorie].push(produkt);
    return acc;
  }, {});

  const gesamtAnzahl = produkte.reduce((sum, p) => sum + p.anzahl, 0);

  return (
    <div className="min-h-screen bg-gray-50 p-8">
      <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg p-6">
        <div className="mb-6">
          <div className="flex items-center space-x-2 mb-2">
            <Package2 className="h-6 w-6 text-blue-500" />
            <h1 className="text-2xl font-bold">Schwundliste</h1>
          </div>
          <p className="text-sm text-gray-500">
            Klicken Sie auf das Plus-Symbol, um die Anzahl zu erhöhen
          </p>
        </div>

        <div className="space-y-6">
          {Object.entries(produkteNachKategorie).map(([kategorie, items]) => (
            <div key={kategorie} className="space-y-2">
              <h3 className="font-semibold text-lg text-gray-700">{kategorie}</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {items.map(produkt => (
                  <div 
                    key={produkt.id} 
                    className="flex items-center justify-between p-3 bg-white rounded-lg border hover:shadow-md transition-shadow"
                  >
                    <div className="flex-1">
                      <div className="font-medium">{produkt.name}</div>
                      {produkt.anzahl > 0 && (
                        <div className="inline-block bg-gray-100 px-2 py-1 rounded-full text-sm mt-1">
                          Anzahl: {produkt.anzahl}
                        </div>
                      )}
                    </div>
                    <div className="flex space-x-2">
                      {produkt.anzahl > 0 && (
                        <button 
                          onClick={() => resetAnzahl(produkt.id)}
                          className="p-2 text-red-500 hover:text-red-600 rounded border"
                        >
                          ×
                        </button>
                      )}
                      <button
                        onClick={() => erhoeheAnzahl(produkt.id)}
                        className="p-2 text-blue-500 hover:text-blue-600 rounded border"
                      >
                        <Plus className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-6 flex justify-between items-center pt-4 border-t">
          <div className="text-sm text-gray-500">
            Gesamtanzahl: {gesamtAnzahl} Produkte
          </div>
          {gesamtAnzahl > 0 && (
            <button 
              className="flex items-center space-x-2 bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg"
              onClick={sendePerEmail}
            >
              <Mail className="h-4 w-4" />
              <span>Per E-Mail senden</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Schwundliste;